import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  getAppointmentError,
  getAppointmentSuccess,
  //plopImportAction
} from "../actions/AppointmentActions.js";
import {
  GET_APPOINTMENT_INIT,
  //plopImportConstant
} from "redux/constants/AppointmentConstants.js";

import FirebaseService from "services/FirebaseService";

export function* GetAppointment() {
  yield takeEvery(GET_APPOINTMENT_INIT, function* () {
    try {
      const data = yield call(
        FirebaseService.fetchCollection,
        "DoctorTimeslot",
        {
          queries: [{ attribute: "available", operator: "==", value: false }],
        }
      );
      data.sort(function (x, y) {
        return y.purchaseTime - x.purchaseTime;
      });
      let changeTime = data.map((element) => {
        if (!element.purchaseTime) {
          return {
            ...element,
          };
        }
        var purchaseTimeFormatter = new Date(0);
        var timeslotTimeFormatter = new Date(0);
        purchaseTimeFormatter.setUTCSeconds(element.purchaseTime.seconds);
        timeslotTimeFormatter.setUTCSeconds(element.timeSlot.seconds);
        return {
          ...element,
          purchaseTime: `${purchaseTimeFormatter}`,
          timeSlot: `${timeslotTimeFormatter}`,
        };
      });

      yield put(getAppointmentSuccess(changeTime));
    } catch (error) {
      console.log(
        "🚀 ~ file: AppointmentSaga.js ~ line 34 ~ yieldtakeEvery ~ error",
        error
      );
      yield put(getAppointmentError(error));
    }
  });
}
//plopSaga
export default function* rootSaga() {
  yield all([
    fork(GetAppointment),
    //plopExport
  ]);
}
