import {
    GET_APPOINTMENT_INIT,
    GET_APPOINTMENT_SUCCESS,
    GET_APPOINTMENT_ERROR
} from '../constants/AppointmentConstants.js';

export const getAppointmentInit = () => ({
    type: GET_APPOINTMENT_INIT,
});

export const getAppointmentSuccess = (data) => ({
    type: GET_APPOINTMENT_SUCCESS,
    data
});

export const getAppointmentError = (error) => ({
    type: GET_APPOINTMENT_ERROR,
    error
});