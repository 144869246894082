export const SET_WITHDRAWAL_PERCENTAGE_INIT = "SET_WITHDRAWAL_PERCENTAGE_INIT";
export const SET_WITHDRAWAL_PERCENTAGE_SUCCESS = "SET_WITHDRAWAL_PERCENTAGE_SUCCESS";
export const SET_WITHDRAWAL_PERCENTAGE_ERROR = "SET_WITHDRAWAL_PERCENTAGE_ERROR";
//plopImport
export const DELETE_IMAGE_CAROUSEL_INIT = "DELETE_IMAGE_CAROUSEL_INIT";
export const DELETE_IMAGE_CAROUSEL_SUCCESS = "DELETE_IMAGE_CAROUSEL_SUCCESS";
export const DELETE_IMAGE_CAROUSEL_ERROR = "DELETE_IMAGE_CAROUSEL_ERROR";
export const GET_IMAGE_CAROUSEL_INIT = "GET_IMAGE_CAROUSEL_INIT";
export const GET_IMAGE_CAROUSEL_SUCCESS = "GET_IMAGE_CAROUSEL_SUCCESS";
export const GET_IMAGE_CAROUSEL_ERROR = "GET_IMAGE_CAROUSEL_ERROR";
export const SAVE_IMAGE_CAROUSEL_INIT = "SAVE_IMAGE_CAROUSEL_INIT";
export const SAVE_IMAGE_CAROUSEL_SUCCESS = "SAVE_IMAGE_CAROUSEL_SUCCESS";
export const SAVE_IMAGE_CAROUSEL_ERROR = "SAVE_IMAGE_CAROUSEL_ERROR";
export const GET_WITHDRAWAL_SETTINGS_INIT = "GET_WITHDRAWAL_SETTINGS_INIT";
export const GET_WITHDRAWAL_SETTINGS_SUCCESS = "GET_WITHDRAWAL_SETTINGS_SUCCESS";
export const GET_WITHDRAWAL_SETTINGS_ERROR = "GET_WITHDRAWAL_SETTINGS_ERROR";