const FirebaseConfig = {
  // apiKey: "",
  // authDomain: "",
  // databaseURL:
  //   "",
  // projectId: "",
  // storageBucket: "",
  // messagingSenderId: "",
  // appId: "",
  // measurementId: "",
  apiKey: "AIzaSyD959eBjFuZogup1rKiPq4TlFoBdyW_eWU",
  authDomain: "medv-163b0.firebaseapp.com",
  projectId: "medv-163b0",
  storageBucket: "medv-163b0.appspot.com",
  messagingSenderId: "710911227726",
  appId: "1:710911227726:web:49928a886847dae17fd01f",
  measurementId: "G-ZJYHG8CM52"
};

export default FirebaseConfig;
